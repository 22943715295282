<template>
  <div>
    <b-card
      no-body
      class="sticky-top-65"
    >
      <b-card-header class="justify-content-between gap-2 py-1">
        <div class="d-flex align-items-end">
          <b-badge
            variant="light-primary"
            class="font-small-4 px-1 py-50 text-uppercase"
          >
            BÁO CÁO | {{ currentDepartmentName }}
          </b-badge>
          <span class="ml-1 font-weight-bolder font-medium-1">{{ reportGeneralTitle }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <div class="d-flex mr-1 gap-1 align-items-center">
            <label class="text-nowrap">Tháng</label>
            <v-select
              v-model="monthFilter"
              class="select-size-sm"
              label="name"
              :options="$monthOptions"
              :clearable="true"
            />
          </div>
          <div class="d-flex gap-1 align-items-center">
            <label class="text-nowrap">Năm</label>
            <v-select
              v-model="yearFilter"
              class="select-size-sm"
              label="name"
              :options="$yearOptions"
              :clearable="true"
            />
          </div>
        </div>
      </b-card-header>
    </b-card>

    <Overview
      :data="sumData"
      :is-pkh="true"
    />
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import reportStoreModule from '@/views/reports/reportStoreModule'
import departmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import vSelect from 'vue-select'
import Overview from './Overview.vue'
import useReportsOverview from '../overview/useReportsOverview'

export default {
  components: {
    BCard,
    BCardHeader,
    Overview,
    BBadge,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.reportMode = this.$route.name
        const q = this.$route.query
        this.currentDepartmentName = q.name || 'NV PKH'
        this.reportTeamType = q.type
        this.creatorId = this.$route.params.id
        if (q.monthFilter !== undefined && q.yearFilter !== undefined) {
          this.monthFilter = q.monthFilter || ''
          this.yearFilter = q.yearFilter || ''
        }
      },
    },
    reportGeneralTitle() {
      const q = this.$route.query
      if (this.monthFilter !== q.monthFilter || this.yearFilter !== q.yearFilter) {
        this.$router.push({
          name: 'reports-user-pkh',
          query: {
            name: this.currentDepartmentName, monthFilter: this.monthFilter, yearFilter: this.yearFilter, t: new Date().getTime(),
          },
        })
      }
    },
  },
  methods: {
    goReportUser(d) {
      this.$router.push({
        name: 'reports-user',
        params: { id: d.user_id },
        query: { monthFilter: this.monthFilter, yearFilter: this.yearFilter, t: new Date().getTime() },
      })
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, departmentStoreModule)

    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
    })

    return {
      ...useReportsOverview(),
    }
  },
}
</script>

<style lang="scss">
</style>
